interface Authorization {
  label: string;
  label2?: string;
  icon: string;
}

export const authorizations: Authorization[] = [
  {
    label: 'Reciclaje y revalorización',
    label2: ' de residuos peligrosos',
    icon: 'images/authorizations/1.svg',
  },
  {
    label: 'Servicios de remediación',
    icon: 'images/authorizations/2.svg',
  },
  {
    label: 'Acopio temporal',
    icon: 'images/authorizations/3.svg',
  },
  {
    label: 'Residuos de manejo',
    label2: 'Especial',
    icon: 'images/authorizations/4.svg',
  },
  {
    label: 'Logística',
    icon: 'images/authorizations/5.svg',
  },
  {
    label: 'Disposición final',
    icon: 'images/authorizations/6.svg',
  },
];
