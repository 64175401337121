import * as React from 'react';
import { Link } from 'react-scroll';

export default function ({ itemClicked }: { itemClicked?: () => void }) {
  const menuItems: { label: string; value: string; desktop?: boolean }[] = [
    { label: 'Quienes somos', value: 'us', desktop: true },
    { label: 'Servicios integrales', value: 'services' },
    { label: 'Presencia', value: 'presence', desktop: true },
    { label: 'Autorizaciones', value: 'authorizations', desktop: true },
    { label: 'Certificaciones', value: 'certifications', desktop: true },
    { label: 'Contacto', value: 'contact' },
  ];

  const handleClick = () => itemClicked && itemClicked();
  return (
    <ul className="lg:flex text-white-100 items-center font-bold">
      {menuItems.map(({ label, value, desktop }) => (
        <li
          key={value}
          className={`mx-3 cursor-pointer py-5 lg:py-0 flex justify-center border-b border-blue-100 lg:border-0 ${
            desktop ? '' : 'lg:hidden'
          }`}
        >
          <Link to={value} smooth={true} offset={-100} duration={500} onClick={handleClick}>
            {label}
          </Link>
        </li>
      ))}
      <li className="flex justify-center py-16 lg:py-0" onClick={handleClick}>
        <a
          className="bg-green-300 lg:bg-blue-500 px-5 py-1 mx-3 rounded-3xl text-blue-500 lg:text-white-100"
          href="https://p.ecolimpio.com/"
          target="_blank"
        >
          Acceso a clientes
        </a>
      </li>
    </ul>
  );
}
