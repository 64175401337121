import * as React from 'react';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';

import Map from '../components/Map';
import MenuItems from '../components/MenuItems';
import ZeroWaste from '../components/ZeroWaste';
import { advantages } from '../constants/advangages';
import { authorizations } from '../constants/authorizations';
import { certificationLogos } from '../constants/certifications';
import { industries } from '../constants/industries';
import { services } from '../constants/services';
import { states, statesWithCities } from '../constants/states';

export default function () {
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const [selectedState, setSelectedState] = useState<string>('');
  const inputFile = useRef<HTMLInputElement>(null);

  const year = new Date().getFullYear();

  const handleSetShowMenu = (value: boolean) => {
    setShowMenu(value);
    value ? setTimeout(() => setShowMenuItems(value), 300) : setShowMenuItems(value);
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ecolimpio | Soluciones Ambientales" />
        <meta
          property="og:description"
          content="Con nuestra filosofía zero waste to landfill te ayudamos a contribuir a un México más limpio y sustentable. Creamos soluciones ambientales a la medida a traves de la gestión, reciclaje, valorización, coprocesamiento y confinamiento seguro de residuos industriales."
        />
        <meta property="og:url" content="http://ecolimpio.net" />
        <meta property="og:site_name" content="Ecolimpio | Soluciones Ambientales" />
        <meta property="og:image" content="/assets/ecolimpio-img.png" />
        <meta property="og:image:secure_url" content="http://ecolimpio.net/assets/ecolimpio-img.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Ecolimpio | Soluciones Ambientale" />
        <title>Ecolimpio | Soluciones Ambientales</title>
        <link rel="icon" type="image/x-icon" href="favicon.ico" />
      </Helmet>

      <main>
        <nav className="h-16 bg-green-900 w-100 p-4 flex justify-center sticky top-0 z-50">
          <div className="flex w-full lg:max-w-5xl">
            <div className="w-1/2 lg:w-1/4">
              <img src="images/logo-white.svg" alt="Company logo" />
            </div>
            <div className="w-1/2 lg:w-3/4 flex justify-end">
              <div className="hidden lg:block">
                <MenuItems />
              </div>
              <button className="bg-transparent lg:hidden" onClick={() => handleSetShowMenu(!showMenu)}>
                <span className="text-white-100 font-bold text-2xl -mt-1 mr-3"> . . .</span>
              </button>
            </div>
          </div>
        </nav>
        <div
          className={`w-full bg-blue-500 fixed ${
            showMenu ? 'h-4/6' : 'h-0'
          } lg:hidden z-20 transition-all duration-500 ease-in-out`}
        >
          {showMenuItems && <MenuItems itemClicked={() => handleSetShowMenu(false)} />}
        </div>
        <div className="bg-green-500 text-white-100 text-center relative flex justify-center">
          <div className="w-full lg:max-w-5xl">
            <p className="text-xl lg:text-3xl p-8 lg:p-24 lg:pb-48 mb-9 font-normal">
              Creamos soluciones ambientales a la medida a través de la gestión, reciclaje, revalorización y disposición
              segura de residuos industriales.
            </p>
            <div className="h-10"></div>
            <div className="absolute -bottom-9 w-full lg:max-w-5xl flex justify-start z-10">
              <img className="lg:w-48 lg:h-48" src="images/doctor.svg" alt="Doctor" />
            </div>
            <div className="absolute -bottom-20 lg:-bottom-48 -bot w-full lg:max-w-5xl flex justify-center z-10">
              <ZeroWaste />
            </div>
          </div>
        </div>
        <div className="bg-green-300 text-blue-500 text-center relative flex justify-center">
          <div className="w-full lg:max-w-5xl">
            <p className="text-xl lg:text-2xl p-10  pt-24 lg:px-52 lg:pt-48">
              Por medio de la Economía Circular te ayudamos a contribuir a un México más limpio y sustentable
            </p>
            <div className="absolute -bottom-9 w-full lg:max-w-5xl flex justify-end">
              <img className="w-24 h-24 lg:w-48 lg:h-48" src="images/workers.png" alt="Workers" />
            </div>
          </div>
        </div>
        <Element
          name="us"
          className="bg-blue-500 text-white-100 py-7 px-10 text-center lg:text-left flex justify-center"
        >
          <div className="w-full lg:max-w-5xl block lg:flex">
            <div className="w-full lg:w-1/3 px-5 lg:pt-5">
              <h3 className="text-green-300 font-bold">Acerca de nosotros</h3>
              <h4 className="text-2xl mb-5 font-bold">Brindamos soluciones ambientales de primer nivel.</h4>
              <p className="mb-5 font-normal">
                Con 26 años de experiencia en la industria en Ecolimpio tenemos un fuerte compromiso con nuestra
                comunidad y con el medio ambiente a través del manejo adecuado de residuos industriales.
              </p>
              <p className="mb-8 font-normal">
                Promovemos la Economía Circular siendo capaces de reciclar o revalorizar más del 80% de todos los
                residuos que gestionamos para nuestros clientes.
              </p>
            </div>
            <div className="w-full lg:w-2/3 px-5">
              <video className="lg:mt-10 rounded-lg" controls>
                <source src="https://arphase-cdn.s3.amazonaws.com/ecolimpio-video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </Element>

        <div className="bg-green-100 py-7 px-10">
          <div className="flex justify-center">
            <Element name="services" className="w-full lg:max-w-5xl flex flex-wrap">
              <div className="w-full lg:w-1/2 px-5 lg:pt-5">
                <h3 className="text-blue-500 font-bold text-2xl lg:text-4xl mb-4">Servicios Integrales.</h3>
                <h3 className="text-blue-500 font-thin text-xl lg:text-3xl mb-8">
                  Soluciones llave en mano para el manejo integral de residuos
                </h3>
                <p className="text-sm text-gray-300 lg:text-base mb-5 font-normal">
                  Ofrecemos soluciones que se adaptan a las necesidades de nuestros clientes, entregando una solución
                  integral para el manejo de todos los residuos generados:
                </p>
                <ul className="text-sm text-gray-300 lg:text-base list-disc pl-3 mb-3 font-normal">
                  <li>Compra de chatarra metálica, plásticos y materiales de embalaje como cartón o tarimas.</li>
                  <li>Disposición de Residuos peligrosos</li>
                  <li>Disposición de Residuos Sólidos urbanos</li>
                  <li>Disposición de Residuos de manejo especial</li>
                </ul>
                <p className="text-sm text-gray-300 lg:text-base mb-8 font-normal">
                  Al integrar todos los procesos con un solo proveedor, facilitamos la administración e incrementamos
                  las oportunidades de mejora enfocadas en una verdadera Economía Circular.
                </p>
              </div>
              <div className="w-full lg:w-1/2 px-0 lg:px-5 flex flex-wrap h-fit">
                <div className="w-full flex">
                  <div className="w-1/2 p-1 lg:p-2">
                    <img className="rounded w-full h-32 lg:h-60" src="images/services/1.png" alt="test" />
                  </div>
                  <div className="w-1/2 p-1 lg:p-2">
                    <img className="rounded w-full h-32 lg:h-60" src="images/services/2.png" alt="doctor" />
                  </div>
                </div>
                <div className="w-full p-1 lg:p-2">
                  <img className="rounded w-full h-32 lg:h-60" src="images/services/3.png" alt="trucks" />
                </div>
              </div>
            </Element>
          </div>

          <div className="flex justify-center mt-20">
            <div className="w-full lg:max-w-5xl flex justify-center flex-wrap text-center">
              <div className="text-blue-500 font-bold text-xl lg:text-4xl">
                <h3>Conoce nuestras </h3>
                <h3 className=" mb-5 lg:mb-16">ventajas competitivas</h3>
              </div>
              <div className="w-full px-7 flex flex-wrap mb-8">
                {advantages.map(({ icon, title, label }, index) => (
                  <div key={index} className="w-full lg:w-1/3 p-1">
                    <div className="p-2 px-0 lg:px-2 text-center">
                      <div className="flex justify-center mb-2">
                        <img
                          className="h-10 w-10 lg:h-14 lg:w-14 mb-8"
                          src={icon}
                          alt={`Logo ${index + 1} ventajas competitivas`}
                        />
                      </div>
                      <div className={index <= 2 ? 'h-20' : 'h-12'}>
                        <span className="text-lg leading-3 text-blue-500">{title}</span>
                      </div>
                      <p className="text-10 sm:text-xs lg:text-base font-normal">{label}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white-100 text-blue-500 text-center pt-20">
          <Element name="presence" className="flex justify-center">
            <div className="w-full lg:max-w-5xl block lg:flex lg:text-left lg:mb-10">
              <div className="px-10 mb-6 w-full lg:w-1/2">
                <h3 className="text-xl lg:text-3xl mb-3 font-bold">Presencia en toda la República Mexicana</h3>
                <p className="lg:text-xl font-normal">
                  Contamos con cobertura de servicios y representantes comerciales a nivel nacional, nuestra fuerza de
                  trabajo está integrada por más de 400 empleados distribuidos en nuestras oficinas centrales, nuestras
                  plantas de reciclaje y revalorización, fortalecidos con una red de Centros de Servicios ubicados en
                  Coahuila, Tlaxcala y Querétaro.
                </p>
              </div>

              <div className="flex justify-center mb-10 w-full lg:w-1/2">
                <Map />
              </div>
            </div>
          </Element>

          <Element name="authorizations" className="flex justify-center">
            <div className="w-full lg:max-w-5xl lg:mb-10">
              <div className="px-10 lg:px-20 mb-5">
                <h3 className="text-xl lg:text-3xl mb-3 font-bold">Nuestras autorizaciones</h3>
                <p className="lg:text-xl font-normal">
                  Contamos con todas las autorizaciones para el tratamiento, reciclaje y coprocesamiento de residuos
                  peligrosos y residuos de manejo especial.
                </p>
              </div>

              <div className="px-7 flex flex-wrap mb-8">
                {authorizations.map(({ icon, label, label2 }, index) => (
                  <div key={index} className="w-1/2 lg:w-1/3 p-1">
                    <div className="p-2 px-0 lg:px-2 border border-blue-500 rounded h-24 lg:h-32">
                      <div className="flex justify-center mb-2">
                        <img className="h-10 w-10 lg:h-14 lg:w-14" src={icon} alt={`Logo ${index + 1} autorization`} />
                      </div>
                      <span className="text-10 sm:text-xs lg:text-base font-normal">{label}</span>
                      {label2 && <p className="text-10 sm:text-xs lg:text-base font-normal -mt-1">{label2}</p>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Element>

          <Element name="certifications" className="flex justify-center px-9 lg:px-0 pb-8">
            <div className="w-full lg:max-w-5xl">
              <h3 className="text-xl lg:text-3xl mb-2 font-bold">Certificaciones</h3>
              <p className="mb-8 lg:px-10 lg:text-xl font-normal">
                Contamos con todas las autorizaciones federales y estatales para el tratamiento, reciclaje y
                revalorización de residuos peligrosos y residuos de manejo especial.
              </p>

              <div className="flex flex-wrap mb-10 lg:mb-20">
                {certificationLogos.map((certification, index) => (
                  <div
                    key={index}
                    className={`${
                      index === certificationLogos.length - 1 ? 'w-full flex justify-center' : 'w-1/2'
                    } lg:w-1/5 p-4 lg:px-0 flex justify-center items-center`}
                  >
                    <img src={certification} alt={`Certification ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          </Element>

          <Element name="contact" className="flex justify-center pb-8">
            <div className="w-full lg:max-w-5xl shadow-none lg:shadow-2xl px-10 lg:px-20 py-7">
              <h3 className="text-xl lg:text-3xl mb-4 font-bold">Contáctanos</h3>
              <p className="mb-8 lg:px-10 lg:text-xl font-normal text-gray-300">
                Contamos con la infraestructura adecuada, modernas instalaciones, unidades de transporte propias y un
                sistema tecnológico de vanguardia que nos permite operar 24/7 a nivel nacional y para cualquier tipo de
                industria.
              </p>
              <form
                action="https://getform.io/f/0a9fcf4c-d72f-4901-85d6-5ddc4a316172"
                method="POST"
                encType="multipart/form-data"
                className="flex flex-wrap"
              >
                <div className="w-full lg:w-1/2 p-2">
                  <input required className="input" name="Nombre" type="text" placeholder="Nombre" />
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <input required className="input" name="Correo" type="email" placeholder="Correo electrónico" />
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <input required className="input" name="Telefono" type="phone" placeholder="Teléfono" />
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <input required className="input" name="Empresa" type="text" placeholder="Empresa" />
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <select
                    required
                    className="input"
                    name="Estado"
                    onChange={event => setSelectedState(event.target.value)}
                  >
                    <option value="">Estado</option>
                    {states.map(state => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <select required className="input" name="Ciudad">
                    <option value="">Ciudad</option>
                    {(statesWithCities[selectedState] ?? []).map(city => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <select required className="input" name="Industria">
                    <option value="">Industria</option>
                    {industries.map(industry => (
                      <option key={industry} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full lg:w-1/2 p-2">
                  <select required className="input" name="Tipo de servicio">
                    <option value="">Tipo de servicio</option>
                    {services.map(service => (
                      <option key={service} value={service}>
                        {service}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full p-2">
                  <textarea
                    required
                    className="input h-40 lg:h-32"
                    name="Comentarios"
                    placeholder="Comentarios (Favor de especificar su requerimiento como tipo de residuos, frecuencia de recolección, ubicación del residuo, volumen estimado, etc.)"
                  />
                </div>
                <div className="w-full mt-3 lg:mt-7 flex flex-wrap justify-start">
                  <input name="Archivo" type="file" className="hidden" ref={inputFile} />
                  <button
                    className="bg-gray-700 px-5 py-1 mx-3 mb-5 rounded-3xl text-white-100 mr-7"
                    type="button"
                    onClick={() => inputFile.current?.click()}
                  >
                    Adjuntar archivo
                  </button>
                  <button className="bg-green-500 px-5 py-1 mx-3 mb-5 rounded-3xl text-white-100 mr-7" type="submit">
                    Envíar
                  </button>
                </div>
              </form>
            </div>
          </Element>
        </div>

        <div className="bg-blue-500 pt-20 text-white-100 text-center lg:text-left">
          <div className="w-full lg:max-w-5xl mx-auto">
            <div className="flex justify-center lg:justify-start items-center mb-8">
              <img className="h-12 w-48" src="images/logo-white.svg" alt="Company logo white" />
            </div>

            <div className="px-10 lg:px-0 block lg:flex">
              <div className="mb-5 w-full lg:w-1/3">
                <p className="text-green-300 font-bold">Dirección</p>
                <p className="font-bold"> Ecolimpio.</p>
                <p className="font-light">Servicios Ambientales</p>
                <p className="font-light">La Madrid 2241 República Ote.</p>
                <p className="font-light">Saltillo, Coahuila</p>
              </div>

              <div className="mb-5 w-full lg:w-1/3">
                <p className="text-green-300 font-bold">Contáctanos</p>
                <p className="font-light">
                  <a href="mailto:contacto@ecolimpio.com.mx">contacto@ecolimpio.com.mx</a>
                </p>

                <p className="font-light">
                  <a href="https://wa.link/hugx0u" target="_blank">
                    WhatsApp +52 1 844 218 0487
                  </a>
                </p>
              </div>

              <div className="mb-12 w-full lg:w-1/3">
                <p className="text-green-300 font-bold">Ayúdanos a mejorar</p>
                <p className="font-light">Sugerencias y comentarios:</p>
                <p className="font-light">
                  <a href="mailto:servicio.cliente@ecolimpio.com.mx">servicio.cliente@ecolimpio.com.mx</a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="border border-blue-300 my-5"></div>
          </div>

          <div className="w-full lg:max-w-5xl mx-auto">
            <div className="pb-5 block lg:flex justify-center">
              <p>Copyright © {year}</p>
              <span className="hidden lg:block mx-3">|</span>
              <p>Todos los derechos reservados</p>
              <span className="hidden lg:block mx-3">|</span>
              <p className="text-blue-300">
                <a href="https://www.tonico.mx" target="_blank">
                  Diseño y Desarrollo por Tónico.Studio
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
