interface Advantage {
  title: string;
  label: string;
  icon: string;
}

export const advantages: Advantage[] = [
  {
    title: 'Unidades de transporte propias:',
    label:
      'Contamos con unidades propias, de modelo reciente y 100% trazables, en cumplimiento con las autorizaciones correspondientes.',
    icon: 'images/advantages/1.svg',
  },
  {
    title: 'Total control de la cadena de trazabilidad de residuos peligrosos:',
    label:
      'Al contar con sitios de disposición final propios somos capaces de confinar, con excelente trazabilidad y transparencia, los residuos que no pueden ser reciclados ni revalorizados.',
    icon: 'images/advantages/2.svg',
  },
  {
    title: 'Personal In Plant altamente calificado:',
    label:
      'Capacitamos y administramos personal In Plant con excelencia en segregación de residuos y mejora continua en la operación.',
    icon: 'images/advantages/3.svg',
  },
  {
    title: 'Alianzas estratégicas de gran valor:',
    label:
      'Contamos con la mejor propuesta del mercado en la valorización de chatarra y plásticos con proveedores exclusivos.',
    icon: 'images/advantages/4.svg',
  },
  {
    title: 'Sistemas de información:',
    label:
      'Diseñamos y generamos estadísticos diarios facilitando la administración de KPIs. Mantenemos control total de nuestros procesos a través del sistema SAP.',
    icon: 'images/advantages/5.svg',
  },
  {
    title: 'Revalorización inteligente:',
    label:
      'Mantenemos altos estándares en nuestro proceso de revalorización, el material que no se logra reciclar es revalorizado por medio de procesos térmicos y físico-químicos para garantizar la menor cantidad de residuos enviados a confinamiento.',
    icon: 'images/advantages/6.svg',
  },
];
