export const industries: string[] = [
  'Automotriz',
  'Consultoría Ambiental',
  'Electrodomésticos',
  'Metalmecánico',
  'Químico Farmacéutico',
  'Recolector / Acopiador de residuos',
  'Siderúgico / Minero',
  'Otro',
];
