import React from 'react';

export default function () {
  return (
    <svg
      width="172px"
      height="172px"
      viewBox="0 0 172 172"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="lg:w-96 lg:h-96"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-k5ocumws1j-1">
          <stop stopColor="#C2FF6B" offset="0%"></stop>
          <stop stopColor="#8DCB07" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Landing-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ecolimpio-landing-mobile" transform="translate(-60.000000, -204.000000)">
          <g id="Zero-waste-to-landfill" transform="translate(61.000000, 205.000000)">
            <g id="Group-13" transform="translate(27.500000, 27.500000)">
              <circle id="Oval" fill="url(#linearGradient-k5ocumws1j-1)" cx="57.6" cy="57.6" r="57.6"></circle>
              <circle id="Oval" fill="#084256" cx="57.6" cy="57.6" r="48.6"></circle>
              <g id="Group-4" transform="translate(19.000000, 24.000000)" fontWeight="400">
                <text id="zero" fontSize="34.5515625" letterSpacing="-0.575859368" fill="#B3F553">
                  <tspan x="1.6914742" y="28">
                    zero
                  </tspan>
                </text>
                <text id="waste" fontSize="26.578125" letterSpacing="-0.442968756" fill="#FFFFFF">
                  <tspan x="1.09007033" y="46.0425">
                    waste
                  </tspan>
                </text>
                <text id="to-landfill" fontSize="13.2890625" letterSpacing="-0.221484378" fill="#FFFFFF">
                  <tspan x="8.07550392" y="61.085">
                    to landfill
                  </tspan>
                </text>
              </g>
            </g>
            <circle id="Oval" stroke="#FFFFFF" strokeWidth="0.5" opacity="0.5" cx="85" cy="85" r="70"></circle>
            <circle id="Oval" stroke="#FFFFFF" strokeWidth="0.5" opacity="0.5" cx="85" cy="85" r="85"></circle>
            <circle id="Oval" fill="#084256" cx="159.5" cy="44" r="10.5"></circle>
            <g
              id="Group"
              transform="translate(153.500000, 37.500000)"
              stroke="#7EBC25"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            >
              <g id="Regular">
                <line x1="0.375" y1="7.125" x2="0.375" y2="11.625" id="Shape"></line>
                <path
                  d="M7.875,10.875 C7.875,10.0465729 7.20342712,9.375 6.375,9.375 L4.5,9.375 C4.5,8.54657288 3.82842712,7.875 3,7.875 L0.375,7.875 L0.375,10.875 L7.875,10.875 Z"
                  id="Shape"
                ></path>
                <line x1="3" y1="9.375" x2="4.5" y2="9.375" id="Shape"></line>
                <circle id="Oval" cx="7.875" cy="4.125" r="3.75"></circle>
                <path
                  d="M5.822,0.987 L7.916,2.4025 C8.12464745,2.54158616 8.25,2.77574371 8.25,3.0265 L8.25,3.885 C8.25,4.24256186 7.99762655,4.55041788 7.647,4.6205 L6.375,4.875 L6.647,5.963 C6.71030459,6.21914603 6.63435549,6.4897147 6.447,6.6755 L5.8195,7.2635"
                  id="Shape"
                ></path>
                <path
                  d="M11.606,4.5065 L10.003,5.0405 C9.64351879,5.15942493 9.42906216,5.52764696 9.503,5.899 L9.799,7.343"
                  id="Shape"
                ></path>
              </g>
            </g>
            <circle id="Oval-Copy-3" fill="#084256" cx="15" cy="132.5" r="6"></circle>
            <g
              id="Group"
              transform="translate(11.500000, 128.500000)"
              stroke="#DDFFA9"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
            >
              <g id="Regular">
                <line x1="6.283225" y1="6.322225" x2="4.5669" y2="6.665425" id="Shape"></line>
                <path
                  d="M4.766775,4.739475 L3.965,5.07 C3.70647207,5.17238921 3.41314145,5.05327309 3.29919249,4.79962791 C3.18524353,4.54598274 3.29100415,4.24757665 3.53925,4.1223 L4.342325,3.7323 C4.51880821,3.64420144 4.72372854,3.63245142 4.909125,3.6998 L6.374875,4.26075"
                  id="Shape"
                ></path>
                <path
                  d="M1.516775,6.689475 L2.122575,6.689475 L3.13235,7.457125 C3.23784849,7.57588174 3.41826471,7.59022325 3.5412,7.489625 L5.056025,6.2725 C5.18048016,6.1705016 5.20068668,5.98777696 5.101525,5.86105 L4.268875,4.943575"
                  id="Shape"
                ></path>
                <path
                  d="M1.516775,4.523025 L2.7495,3.9975 C2.95371656,3.91581137 3.18564462,3.94543228 3.362775,4.075825 L3.4684,4.1626"
                  id="Shape"
                ></path>
                <path
                  d="M0.433225,7.123025 L1.083225,7.123025 C1.19820961,7.123025 1.30848475,7.07734754 1.38979114,6.99604114 C1.47109754,6.91473475 1.516775,6.80445961 1.516775,6.689475 L1.516775,4.523025 C1.516775,4.28358195 1.32266805,4.089475 1.083225,4.089475 L0.433225,4.089475"
                  id="Shape"
                ></path>
                <path
                  d="M7.366775,7.123025 L6.716775,7.123025 C6.60179039,7.123025 6.49151525,7.07734754 6.41020886,6.99604114 C6.32890246,6.91473475 6.283225,6.80445961 6.283225,6.689475 L6.283225,4.523025 C6.283225,4.28358195 6.47733195,4.089475 6.716775,4.089475 L7.366775,4.089475"
                  id="Shape"
                ></path>
                <path
                  d="M3.21425,2.16385 C3.21425,2.16385 2.82165,2.83595 2.25225,2.74105 C1.098175,2.54865 1.29025,1.00945 0.905775,0.43355 C1.66143013,0.124844382 2.51917886,0.196363712 3.213275,0.62595 C3.79145,1.00945 3.79145,1.58665 3.21425,2.16385 Z"
                  id="Shape"
                ></path>
                <path
                  d="M4.92375,2.3075 C4.92375,2.3075 5.196425,2.7742 5.59195,2.708225 C6.3934,2.57465 6.259825,1.505725 6.526975,1.105 C6.00197949,0.890425445 5.40599842,0.940080467 4.92375,1.238575 C4.523025,1.5067 4.523025,1.90775 4.92375,2.3075 Z"
                  id="Shape"
                ></path>
                <path
                  d="M2.44465,1.21875 C2.44465,1.21875 3.63025,2.059525 3.82265,3.9832 C4.05846036,3.34587134 4.41798221,2.76146886 4.880525,2.263625"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
